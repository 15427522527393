import request from '@/utils/request'


// 查询用户成长值变动记录列表
export function listGrowthLog(query) {
  return request({
    url: '/user/user-growth-log/list',
    method: 'get',
    params: query
  })
}

// 查询用户成长值变动记录分页
export function pageGrowthLog(query) {
  return request({
    url: '/user/user-growth-log/page',
    method: 'get',
    params: query
  })
}

// 查询用户成长值变动记录详细
export function getGrowthLog(data) {
  return request({
    url: '/user/user-growth-log/detail',
    method: 'get',
    params: data
  })
}

// 新增用户成长值变动记录
export function addGrowthLog(data) {
  return request({
    url: '/user/user-growth-log/add',
    method: 'post',
    data: data
  })
}

// 修改用户成长值变动记录
export function updateGrowthLog(data) {
  return request({
    url: '/user/user-growth-log/edit',
    method: 'post',
    data: data
  })
}

// 删除用户成长值变动记录
export function delGrowthLog(data) {
  return request({
    url: '/user/user-growth-log/delete',
    method: 'post',
    data: data
  })
}
